import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/layouts/layout';
import SEO from '../../components/seo';
import ArticlePagination from '../../components/blog/article-pagination';
import RecommendedArticles from '../../components/blog/recommended-articles';
import Meta from '../../components/blog/meta';
import getSocialImageURL from '../../lib/get-social-image-url';

export const query = graphql`
  query GetArticlePageSettingsWithLocale($locale: String!, $article_id: String!) {
    pageSettings: contentfulBlogPageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords
      mainHeading
      categoriesText
      shareCalloutText
      paginationPrevious
      paginationNext
    }

    article: contentfulBlogArticle(id: { eq: $article_id }) {
      title
      slug
      description {
        description
      }
      keywords
      content {
        childMarkdownRemark {
          html
        }
      }
      categories {
        title
        slug
      }
      excerpt {
        excerpt
      }

      author {
        firstName
        lastName
        slug
        bio {
          bio
        }
        socialMediaProfiles {
          name
          url
        }
        avatar {
          resize(width: 100, height: 100, quality: 100) {
            src
          }
        }
      }
    }
  }
`;

function ArticlePage({ data, pageContext }) {
  const { article, pageSettings } = data;
  const { previous, next, recommended } = pageContext;

  return (
    <Layout>
      <SEO
        image={getSocialImageURL(
          article.title,
          `${article.categories.map(c => `#${c.title}`).join(' ')}`
        )}
        title={article.title}
        description={article.description.description}
        keywords={article.keywords}
      />
      <article
        className="blog-article max-w-4xl w-full m-auto"
        itemID={`${process.env.SITE_URL}/blog/${article.slug}`}
        itemScope
        itemType="https://schema.org/BlogPosting"
      >
        <header className="text-center">
          <Meta author={article.author} categories={article.categories} />
          <h1 className="blog-article-title" itemProp="headline">
            {article.title}
          </h1>
        </header>
        <main className="prose prose-indigo lg:prose-xl" itemProp="articleBody">
          <section
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: article.content.childMarkdownRemark.html,
            }}
          />
          {/* <PromotedContent data={article.promotedContent} /> */}
        </main>
        <footer className="leading-normal">
          <ArticlePagination
            previous={previous}
            next={next}
            defaults={{
              previous: pageSettings.paginationPrevious,
              next: pageSettings.paginationNext,
            }}
          />
          <RecommendedArticles articles={recommended} />
        </footer>
      </article>
    </Layout>
  );
}

export default ArticlePage;
