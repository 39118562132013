const urlBase = 'https://res.cloudinary.com/weremote-ro/image/upload';
const titleOptions =
  'w_1200,h_628,c_fill,q_auto,f_auto/w_800,c_fit,co_rgb:232129,g_south_west,x_300,y_254,l_text:arial_64_bold';
const textOptions = 'w_800,c_fit,co_rgb:232129,g_north_west,x_300,y_415,l_text:arial_38';
const template = 'assets/social-images/horizontal';

const getSocialImageURL = (title, text) =>
  `${urlBase}/${titleOptions}:${encodeURIComponent(title).replace(
    /\//g,
    '%252F'
  )}/${textOptions}:${encodeURIComponent(text).replace(/%2F/gi, '%252F')}/${template}`;

export default getSocialImageURL;
