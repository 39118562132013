import React from 'react';
import { Link } from 'gatsby';

const RecommendedArticles = ({ articles }) =>
  articles ? (
    <div className="mt-8">
      <h2 className="text-gray-600">Related articles</h2>
      <ul>
        {articles.map(article => (
          <li>
            <Link className="text-theme-purple text-xl" to={`/blog/${article.slug}`}>
              {article.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ) : null;

export default RecommendedArticles;
