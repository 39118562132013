import React from 'react';
import { Link } from 'gatsby';

const ArticlePagination = ({ previous, next, defaults }) => (
  <div className="mt-3 flex flex-wrap justify-between align-center">
    {previous ? (
      <Link className="text-theme-purple font-bold text-lg" to={`/blog/${previous.slug}`}>
        &larr; {`${defaults.previous} article`}
      </Link>
    ) : (
      <span />
    )}
    {next ? (
      <Link className="text-theme-purple font-bold text-lg" to={`/blog/${next.slug}`}>
        {`${defaults.next} article`} &rarr;
      </Link>
    ) : (
      <span />
    )}
  </div>
);

export default ArticlePagination;
